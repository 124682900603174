import { servicePlain } from '@/utils/request'
import { MAIL_ENDPOINT } from '@/settings.js'
const submitContact = data => {
    return servicePlain({
        url: MAIL_ENDPOINT,
        method: 'post',
        data
    })
}

export { submitContact }
