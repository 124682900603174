<template>
  <div>
    <el-form
      ref="contact"
      :rules="rules"
      :label-position="labelPosition"
      :model="contact"
      size="small"
    >
      <el-row :gutter="20">
        <el-col :span="24" :sm="12">
          <el-form-item label="First name" prop="first_name">
            <el-input v-model="contact.first_name" placeholder="First name" />
          </el-form-item>
        </el-col>
        <el-col :span="24" :sm="12">
          <el-form-item label="Last name" prop="last_name">
            <el-input
              v-model="contact.last_name"
              placeholder="Last name"
            >/>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Email address" prop="email">
            <el-input v-model="contact.email" placeholder="Email address" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Comment/Query" prop="comment">
            <el-input
              v-model="contact.comment"
              type="textarea"
              :rows="3"
              placeholder="Comment/Query"
            />
          </el-form-item>
          <el-form-item label="" prop="opt_in">
            <el-checkbox v-model="contact.opt_in">
              Please tick this box if you would like to receive further information regarding our products and promotions.
            </el-checkbox>
          </el-form-item>
          <el-form-item label="" prop="consent">
            <el-checkbox v-model="contact.consent">
                Please tick this box if you give us your consent to respond to your query or comment. Any personal information you provide to us will be held in accordance with our <a href="https://www.pepsico.com.au/privacy-policy" target="_blank">Privacy Policy</a>
            </el-checkbox>
          </el-form-item>

        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-alert
            v-if="response.message"
            class="mt-4"
            :title="response.title"
            :type="response.type"
            :description="response.message"
            :closable="response.closable"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20" justify="center" type="flex" class="mt-5 pb-5">
        <el-col :sm="24" :md="12">
          <el-button
            v-loading="loading"
            type="primary"
            class="submit-btn"
            style="width:100%"
            @click="submit()"
          >Submit</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { submitContact } from '@/api/contact'
import {ADMIN_EMAIL, ACTIVITY_CONTACT_SUBJECT, PRIVACY_POLICY_URL} from '@/settings'

export default {
    data() {
        return {
            labelPosition: 'top',
            loading: false,
            privacy: PRIVACY_POLICY_URL,
            contact: {
                first_name: null,
                last_name: null,
                email: null,
                comment: null,
                opt_in: false,
                consent: false
            },
            response: {
                type: 'error',
                title: 'Error',
                message: null,
                closable: false
            },
            rules: {
                first_name: [
                    {
                        required: true,
                        message: 'Please input your first name',
                        trigger: 'blur'
                    }
                ],
                last_name: [
                    {
                        required: true,
                        message: 'Please input your last name',
                        trigger: 'blur'
                    }
                ],

                email: [
                    {
                        type: 'email',
                        required: true,
                        message: 'Please input your email address',
                        trigger: 'blur'
                    }
                ],
                comment: [
                    {
                        required: true,
                        message: 'Please input your comment',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    computed: {
        message() {
            let msg = `First Name: ${this.contact.first_name}` + '\n'
            msg += `Last Name: ${this.contact.last_name}` + '\n'
            msg += `Email: ${this.contact.email}` + '\n'
            msg += `comment: ${this.contact.comment}` + '\n'
            return msg
        }
    },

    methods: {
        submit() {
            this.$refs['contact'].validate(valid => {
                if (valid) {
                 
                    this.loading = true
                    submitContact({
                        'data': {
                            'name': this.contact.first_name + ' ' + this.contact.last_name,
                            'email': this.contact.email,
                            'contact_number': null,
                            'message': this.contact.comment,
                            'opt_in': this.contact.opt_in.toString(),
                            'consent': this.contact.consent.toString(),
                            'subject':  ACTIVITY_CONTACT_SUBJECT
                        },
                        'to': [ADMIN_EMAIL],
                    })
                        .then(() => {
                            this.setSuccess()
                        })
                        .catch(error => this.setError(error.message ?? error))
                          
                } else {
                    this.setError('Please complete all required fields.')

                    return false
                }
            })
        },
        setError(errorMsg) {
            this.loading = false
            this.response.type = 'error'
            this.response.title = 'Error'
            this.response.message = errorMsg
        },
        setSuccess() {
            this.loading = false
            this.response.type = 'success'
            this.response.title = 'Success'
            this.response.message = 'Thank you for your message!'

            this.contact = {
                first_name: null,
                last_name: null,
                email: null,
                comment: null
            }
        }
    }
}
</script>

<style>
.el-checkbox__label {
  white-space: break-spaces;
}
.el-checkbox:last-of-type {
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 0.5em;
}
</style>
