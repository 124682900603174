<template>
  <app-main>
    <template #headerText>
      <h1>Contact Us</h1>
    </template>
    <template #headerSubtext>
      <h5>Do you have a question about the promotion?</h5>
      <p>
        Please complete the form below and we’ll get back to you within 3
        business days. Requests and queries submitted via this page do not
        constitute as an entry into this promotion.
      </p>
    </template>
    <contact-form />
  </app-main>
</template>

<script>
import ContactForm from '@/components/Contact/ContactForm'

export default {
    name: 'AppContact',
    components: {
        ContactForm
    }
}
</script>
